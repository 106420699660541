import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
  FormDataConsumer,
  Labeled,
  NumberInput
} from 'react-admin';
import cfg from "../Config";
import {makeStyles} from "@material-ui/core/styles";
import {DateField} from 'react-admin';

const useStyles = makeStyles({
  media: {
    height: '300px',
    width: '400px',
  },
  image: {
    height: '300px',
    width: '400px',
    maxHeight: '300px',
  }
});

export const GalleryEdit = (props) => {
  const classes = useStyles(props);
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput fullWidth={true} disabled label="Id" source="id"/>
        <ImageInput source="image" label="select image" accept="image/*" validate={required()}>
          <ImageField classes={classes} source="src" title="title"/>
        </ImageInput>

        <FormDataConsumer>
          {({formData}) => {
            if (typeof formData.image === "string") {
              return (
                <div>
                  <Labeled label="Original image">
                    <img alt={"AVATAR"} className={classes.media}
                         src={cfg.backUrl + "/image/gallery/full/" + formData.image}/>
                  </Labeled>
                </div>
              );
            }
          }}
        </FormDataConsumer>

        <NumberInput fullWidth={true} source="priority" step={1} min={1}/>
        <DateField showTime={true} fullWidth={true} source="date"/>

      </SimpleForm>
    </Edit>
  )
};

