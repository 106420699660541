import * as React from "react";
import {Datagrid, List, TextField} from 'react-admin';

export const OrderList = props => (
  <List  {...props}>
    <Datagrid rowClick={"edit"}>
      <TextField source="id" label="ID"/>
      <TextField source="price" label="Price"/>
      <TextField source="status" label="Status"/>
      <TextField source="description" label="Description"/>
      <TextField source="statusName" label="Status" sortBy="status"/>
      <TextField source="created_at" label="Created"/>
    </Datagrid>
  </List>
);