    import * as React from "react";
    import {
        Create,
        SimpleForm,
        TextInput,
        ImageInput,
        ImageField,
        required,
        NumberInput,
        SimpleFormIterator, ArrayInput
    } from 'react-admin';
    import RichTextInput from 'ra-input-rich-text';
    import {makeStyles} from "@material-ui/core/styles";

    const useStyles = makeStyles({
        image: {
            height: '500px',
            width: '400px',
            maxHeight: '500px',
        }
    });

    export const PostCreate = (props) => {
        const classes = useStyles(props);
        return (<Create {...props}>
            <SimpleForm>
                <TextInput fullWidth={true} source="seo_title" validate={required()}/>
                <TextInput fullWidth={true} source="seo_description" validate={required()}/>
                <TextInput fullWidth={true} source="seo_keywords" validate={required()}/>

                <ImageInput source="image" label="Related pictures" accept="image/*" validate={required()}>
                    <ImageField classes={classes} source="src" title="title"/>
                </ImageInput>
                <TextInput fullWidth={true} source="name" validate={required()}/>
                <TextInput fullWidth={true} source="name_in_url"/>
                <RichTextInput source="article" validate={required()}/>
                <NumberInput fullWidth={true} source="priority" step={1} min={1} />
                <ArrayInput source="additionalPostInfo">
                    <SimpleFormIterator>
                        <RichTextInput label="article" source="article"/>
                        <ImageInput source="image" label="Related pictures" accept="image/*">
                            <ImageField classes={classes} source="src" title="title"/>
                        </ImageInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>)
    }