import * as React from "react";
import {Edit, SimpleForm, TextInput, required} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

export const FaqEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput fullWidth={true} disabled label="Id" source="id"/>
                <RichTextInput source="article" validate={required()} />
            </SimpleForm>
        </Edit>
    )
};

