import * as config from '12factor-config';

// export const backUrl = "https://admin-p.motorcar-tuning.com.ua/api";
// export const backUrl = "http://localhost:8080/api";

const conf = config({
  backUrl: {
    env: 'REACT_APP_BACK_URL',
    type: 'string',
    default: 'http://admin.dev.motorcar-tuning.com.ua/api',
    // default: 'http://localhost:8080/api',
    required: true,
  },
});

export default conf;