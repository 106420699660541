import * as React from 'react';
import { useListContext } from 'react-admin';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cfg from "../Config";

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: '500px',
    },
    title: {
        paddingBottom: '0.5em',
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
});

export const EquipmentGrid = props => {
    const classes = useStyles(props);
    const { data, ids } = useListContext();

    const openEditView = (id) =>  {
        window.open(window.location.href + "/" + data[id].id,"_self");
    }

    return ids ? (
        <Grid container spacing={2} className={classes.root}>
            {ids.map(id => (
                <Grid key={id} xs={12} sm={12} md={6} lg={4} xl={4} item>
                    <Card onClick={() => openEditView(id)}>
                        <CardMedia
                            image={cfg.backUrl + "/image/equipment/" + data[id].image}
                            className={classes.media}
                        />
                        <CardContent className={classes.title}>
                            <Typography
                                variant="h5"
                                component="h2"
                                align="center"
                            >
                                {data[id].name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    ) : null;
};
