import * as React from "react";
import { List, Datagrid, TextField} from 'react-admin';

export const PriceList = (props) => (
    <List {...props}>
        <Datagrid rowClick={"edit"}>
            <TextField source="name" />
            <TextField source="price" />
        </Datagrid>
    </List>
);