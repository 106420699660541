import * as React from "react";
import { Create, SimpleForm, TextInput,
    ImageInput, ImageField, ArrayInput,
    SimpleFormIterator ,required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    image: {
        height: '500px',
        width: '400px',
        maxHeight: '500px',
    }
});

export const EquipmentCreate = (props) => {
    const classes = useStyles(props);

    return (
        <Create {...props}>
        <SimpleForm>
            <ImageInput source="image" label="Related pictures" accept="image/*" validate={required()}>
                <ImageField classes={classes}  source="src" title="title"/>
            </ImageInput>
            <TextInput fullWidth={true} source="name" validate={required()}/>
            <TextInput fullWidth={true} source="name_in_url"/>
            <TextInput fullWidth={true} source="price" validate={required()}/>
            <TextInput fullWidth={true} multiline={true} source="main_article" validate={required()}/>
            <RichTextInput source="full_article" validate={required()} />
            <ArrayInput source="characteristics">
                <SimpleFormIterator>
                    <TextInput fullWidth={true} validate={required()}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
)};
