import * as React from "react";
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';

const ProductFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn/>
  </Filter>
);

export const ProductList = props => (
  <List  {...props} filters={<ProductFilter/>}>
    <Datagrid rowClick={"edit"}>
      <TextField source="id" label="ID"/>
      <TextField source="title" label="Title"/>
      <TextField source="sku" label="Sku"/>
      <TextField source="categoryTitle" label="Category" sortBy="category_id"/>
      <TextField source="price" label="Price"/>
      <TextField source="priority" label="Priority"/>
    </Datagrid>
  </List>
);