import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    ImageInput,
    ImageField,
    FormDataConsumer,
    Labeled,
    SimpleFormIterator, ArrayInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import cfg from "../Config";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    media: {
        height: '500px',
        width: '400px',
    },
    image: {
        height: '500px',
        width: '400px',
        maxHeight: '500px',
    }
});

export const EquipmentEdit = (props) => {
    const classes = useStyles(props);
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput fullWidth={true} disabled label="Id" source="id"/>
                <ImageInput source="image" label="select image" accept="image/*" validate={required()}>
                    <ImageField classes={classes} source="src" title="title"/>
                </ImageInput>

                <FormDataConsumer>
                    {({formData}) => {
                        if (typeof formData.image === "string") {
                            return (
                                <div>
                                    <Labeled label="Original image">
                                        <img alt={"AVATAR"} className={classes.media} src={cfg.backUrl + "/image/equipment/" + formData.image}/>
                                    </Labeled>
                                </div>
                            );
                        }
                    }}
                </FormDataConsumer>

                <TextInput fullWidth={true} source="name" validate={required()}/>
                <TextInput fullWidth={true} source="name_in_url"/>
                <TextInput fullWidth={true} source="price" validate={required()}/>
                <TextInput fullWidth={true} multiline={true} source="main_article" validate={required()}/>
                <RichTextInput source="full_article" validate={required()} />

                <ArrayInput source="characteristics">
                    <SimpleFormIterator>
                        <TextInput fullWidth={true} validate={required()}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    )
};

