import simpleRestProvider from 'ra-data-simple-rest';
import {fetchUtils} from "react-admin";
import cfg from "../Config";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  // add your own headers here
  const {accessToken} = JSON.parse(localStorage.getItem('auth')) || {};
  options.headers.set('Authorization', `Bearer ${accessToken}`);
  options.headers.set('X-Custom-Header', 'foobar');
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(cfg.backUrl, httpClient, 'X-Total-Count');

const createEx = (resource, params) => {
  const paramAdditionalData = params.data.additionalPostInfo ? params.data.additionalPostInfo : [];
  const imageData = JSON.parse(JSON.stringify(params.data.image));
  const additionalPostInfo = JSON.parse(JSON.stringify(paramAdditionalData));

  return Promise.all([convertFileToBase64(params.data.image),
    ...paramAdditionalData.map((childPost) => convertFileToBase64(childPost.image))])
    .then(base64Pictures => {
        const base64Arr = [];
        for (let i = 0; i < base64Pictures.length; i++) {
          if (i === 0) {
            base64Arr.push({
              ...imageData,
              base64String: base64Pictures[i]
            })
          } else {
            base64Arr.push({
              ...additionalPostInfo[i - 1].image,
              base64String: base64Pictures[i],
            })
          }
        }
        return base64Arr;
      }
    )
    .then(transformedNewPictures => {
      const transformedAdditionalPostInfo = additionalPostInfo.map((childPost, idx) => {
        const image = transformedNewPictures[idx + 1].base64String ?
          transformedNewPictures[idx + 1] : null;
        return {
          article: childPost.article,
          image: image
        }
      })

      return dataProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          image: transformedNewPictures[0],
          additionalPostInfo: transformedAdditionalPostInfo,
        },
      })
    });
}

const createProduct = (resource, params) => {
  return Promise.all([formImages(params), formDownloads(params)])
    .then(([inImages, inDownloads]) => {
      console.log('!@# CustomDataProvider.js:():67 ', inImages, inDownloads);
      return dataProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          inImages,
          inDownloads,
          packages: formPackages(params)
        },
      })
    });
}

const formFiles = (files, key = 'image') => {
  return Promise.all([...files.map((file) => convertFileToBase64(file[key]))])
    .then(base64file => {
        const base64Arr = [];
        for (let i = 0; i < base64file.length; i++) {
          if (typeof files[i].url === "string") {
            base64Arr.push(files[i])
          } else {
            base64Arr.push({
              ...files[i][key],
              base64String: base64file[i],
            })
          }
        }
        return base64Arr;
      }
    )
    .then(transformedFiles => {
      return files.map((childPost, idx) => {
        const file = typeof transformedFiles[idx].url === "string" ?
          transformedFiles[idx] :
          transformedFiles[idx].base64String ?
            transformedFiles[idx] : null;
        return {
          article: childPost.article,
          [key]: file
        }
      })
    })
}

const formImages = (params) => {
  const images = [
    ...(params.data.images ? params.data.images : [])
  ];

  return formFiles(images)
}

const formDownloads = (params) => {
  const downloads = [
    ...(params.data.downloads ? params.data.downloads : [])
  ];

  return formFiles(downloads)
}

const formPackages = (params) => {
  return params.data.packages;
}

const updateEx = (resource, params) => {
  const paramAdditionalData = params.data.additionalPostInfo ? params.data.additionalPostInfo : [];
  const imageData = JSON.parse(JSON.stringify(params.data.image));
  const additionalPostInfo = JSON.parse(JSON.stringify(paramAdditionalData));

  return Promise.all([convertFileToBase64(params.data.image),
    ...paramAdditionalData.map((childPost) => convertFileToBase64(childPost.image))])
    .then(base64Pictures => {
        const base64Arr = [];
        for (let i = 0; i < base64Pictures.length; i++) {
          if (i === 0) {
            if (typeof imageData === "string") {
              base64Arr.push(imageData)
            } else {
              base64Arr.push({
                ...imageData,
                base64String: base64Pictures[i]
              })
            }
          } else {
            if (typeof additionalPostInfo[i - 1].image === "string") {
              base64Arr.push(additionalPostInfo[i - 1].image)
            } else {
              base64Arr.push({
                ...additionalPostInfo[i - 1].image,
                base64String: base64Pictures[i],
              })
            }

          }
        }
        return base64Arr;
      }
    )
    .then(transformedNewPictures => {
      const transformedAdditionalPostInfo = additionalPostInfo.map((childPost, idx) => {
        const image = typeof transformedNewPictures[idx + 1] === "string" ?
          transformedNewPictures[idx + 1] :
          transformedNewPictures[idx + 1].base64String ?
            transformedNewPictures[idx + 1] : null;
        return {
          article: childPost.article,
          image: image
        }
      })

      return dataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          image: transformedNewPictures[0],
          additionalPostInfo: transformedAdditionalPostInfo,
        },
      })
    });
}

const updateProduct = (resource, params) => {
  return Promise.all([formImages(params), formDownloads(params)])
    .then(([inImages, inDownloads]) => {
      console.log('!@# CustomDataProvider.js:():214 ', inImages, inDownloads);
      return dataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          inImages,
          inDownloads,
          packages: formPackages(params)
        },
      })
    });
}

const customDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
    if (!['post', 'equipment', 'gallery', 'shop/products'].includes(resource)) {
      return dataProvider.create(resource, params);
    }

    return resource === 'shop/products'
      ? createProduct(resource, params)
      : createEx(resource, params)
  },

  update: (resource, params) => {
    if (!['post', 'equipment', 'gallery', 'shop/products'].includes(resource)) {
      return dataProvider.update(resource, params);
    }

    return resource === 'shop/products'
      ? updateProduct(resource, params)
      : updateEx(resource, params)
  },

  saveClient: (client, previousData) => {
    const {id, ...data} = client
    return dataProvider.update(`shop/orders/client`, {
      id,
      data,
      previousData
    })
  },

  getRootCategories: (id = 0) => {
    return dataProvider.getList(`shop/categories/root/${id}`, {
      pagination: {
        page: 1,
        perPage: 1000
      },
      sort: {},
      filter: null
    })
  },

  getCategoriesTree: () => {
    return dataProvider.getOne(`shop/categories`, {
      id: 'tree'
    })
  }
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    if (file) {
      if (typeof file === "string") {
        resolve(file);
      } else {
        reader.readAsDataURL(file.rawFile);
      }
    } else {
      resolve(null);
    }
  });

export default customDataProvider;