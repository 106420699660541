import * as React from "react";
import {Edit, SimpleForm, TextInput, required, NumberInput} from 'react-admin';

export const PriceEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput fullWidth={true} disabled label="Id" source="id"/>
                <TextInput fullWidth={true} source="name" validate={required()}/>
                <TextInput fullWidth={true} source="price" validate={required()}/>
                <TextInput fullWidth={true} source="article" validate={required()}/>
                <NumberInput fullWidth={true} source="priority" step={1} min={1} />
            </SimpleForm>
        </Edit>
    )
};