import * as React from "react";
import {Admin, Resource} from 'react-admin'
import { createBrowserHistory as createHistory } from 'history';
import {PostList} from "./post/PostList";
import {PostCreate} from "./post/PostCreate";
import {PostEdit} from "./post/PostEdit";
import {PriceList} from "./price/PriceList";
import {PriceCreate} from "./price/PriceCreate";
import {PriceEdit} from "./price/PriceEdit";
import {EquipmentCreate} from "./equipment/EquipmentCreate";
import customDataProvider from "./provider/CustomDataProvider";
import {EquipmentList} from "./equipment/EquipmentList";
import {EquipmentEdit} from "./equipment/EquipmentEdit";
import {ServiceList} from "./service/ServiceList";
import {ServiceCreate} from "./service/ServiceCreate";
import {ServiceEdit} from "./service/ServiceEdit";
import {FaqList} from "./faq/FaqList";
import {FaqEdit} from "./faq/FaqEdit";
import {PartnerEdit} from "./partner/PartnerEdit";
import {PartnerList} from "./partner/PartnerList";
import {PartnerCreate} from "./partner/PartnerCreate";
import authProvider from "./provider/AuthProvider";
import {GalleryList} from "./gallery/GalleryList";
import {GalleryCreate} from "./gallery/GalleryCreate";
import {GalleryEdit} from "./gallery/GalleryEdit";
import {CategoryList} from "./shop/category/CategoryList";
import {CategoryCreate} from "./shop/category/CategoryCreate";
import {CategoryEdit} from "./shop/category/CategoryEdit";
import {ProductList} from "./shop/product/ProductList";
import {ProductCreate} from "./shop/product/ProductCreate";
import {ProductEdit} from "./shop/product/ProductEdit";
import {OrderList} from "./shop/order/OrderList";
import {OrderEdit} from "./shop/order/OrderEdit";
import {ShoppingBasket} from "@material-ui/icons";

const history = createHistory();

const App = () => <Admin
    dataProvider={customDataProvider}
    authProvider={authProvider}
    history={history}
    requireAuth
>
    <Resource name="post" list={PostList} create={PostCreate} edit={PostEdit}/>
    <Resource name="equipment" list={EquipmentList} create={EquipmentCreate} edit={EquipmentEdit}/>
    <Resource name="price" list={PriceList} create={PriceCreate} edit={PriceEdit}/>
    <Resource name="service" list={ServiceList} create={ServiceCreate} edit={ServiceEdit}/>
    <Resource name="partner" list={PartnerList} create={PartnerCreate} edit={PartnerEdit}/>
    <Resource name="gallery" list={GalleryList} create={GalleryCreate} edit={GalleryEdit}/>
    <Resource name="faq" list={FaqList} edit={FaqEdit}/>
    <Resource icon={ShoppingBasket} name="shop/categories" list={CategoryList} create={CategoryCreate} edit={CategoryEdit}/>
    <Resource icon={ShoppingBasket} name="shop/products" list={ProductList} create={ProductCreate} edit={ProductEdit}/>
    <Resource icon={ShoppingBasket} name="shop/orders" list={OrderList} edit={OrderEdit}/>
</Admin>;

export default App;