import * as React from "react";
import {Create, SimpleForm, TextInput, required, NumberInput} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

export const PartnerCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput fullWidth={true} source="name" validate={required()}/>
            <RichTextInput fullWidth={true} source="article" validate={required()}/>
            <NumberInput fullWidth={true} source="priority" step={1} min={1} />
        </SimpleForm>
    </Create>
);