import * as React from "react";
import {
  Edit,
  SimpleForm,
  required,
  NumberInput,
  TextInput,
  SelectInput,
} from 'react-admin';
import {useEffect, useState} from "react";
import customDataProvider from "../../provider/CustomDataProvider";

const ROOT = {
  id: 0,
  name: '-'
}

export const CategoryEdit = (props) => {
  const [roots, setRoots] = useState([
    ROOT
  ]);

  useEffect(() => {
    customDataProvider.getRootCategories().then(list => setRoots([
      ROOT,
      ...list.data.map(({id, title}) => ({id, name: title}))
    ]))
  }, [])

  return (<Edit {...props}>
    <SimpleForm>
      <SelectInput fullWidth={true}  source="parent_id" choices={roots} defaultValue={0}/>
      <TextInput fullWidth={true} disabled label="Id" source="id"/>
      <TextInput fullWidth={true} source="title" validate={required()}/>
      <NumberInput fullWidth={true} source="priority" step={1} min={0}/>
    </SimpleForm>
  </Edit>)
}