import * as React from "react";
import {useEffect, useState} from "react";

import {Button, List, ListItem, ListSubheader, Snackbar, TextField} from "@material-ui/core";
import customDataProvider from "../../provider/CustomDataProvider";
import {Save} from "@material-ui/icons";

export const ClientData = (props) => {
  const {client} = props.record || {}

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [other, setOther] = useState('');
  useEffect(() => {
    setName(client?.name || '')
    setEmail(client?.email || '')
    setPhone(client?.phone || '')
    setAddress(client?.address || '')
    setOther(client?.other || '')
  }, [client]);

  const [openSuccess, setOpenSuccess] = useState(false);

  if (!client) {
    return <div>Loading...</div>
  }

  const saveClient = async () => {
    await customDataProvider.saveClient({
      ...client,
      name,
      email,
      phone,
      address,
      other
    }, client)
    setOpenSuccess(true)
  }

  return (
    <div style={{ width: 400, margin: '1em' }}>

      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Client
          </ListSubheader>
        }
      >
        <ListItem>
          <TextField label="Name" value={name} onChange={e => setName(e.target.value)}/>
        </ListItem>

        <ListItem button>
          <TextField label="Phone" value={phone} onChange={e => setPhone(e.target.value)}/>
        </ListItem>

        <ListItem button>
          <TextField label="Email" value={email} onChange={e => setEmail(e.target.value)}/>
        </ListItem>

        <ListItem button>
          <TextField label="Address" value={address} onChange={e => setAddress(e.target.value)}/>
        </ListItem>

        <ListItem button>
          <TextField multiline label="Other" rows={5} value={other} onChange={e => setOther(e.target.value)}/>
        </ListItem>

        <ListItem>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Save />}
            style={{marginLeft: 'auto'}}
            onClick={saveClient}
          >
            Update
          </Button>
        </ListItem>
      </List>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSuccess}
        autoHideDuration={5000}
        onClose={() => setOpenSuccess(false)}
      >
        <div style={{backgroundColor: '#4caf50', fontWeight: 400, marginTop: '16ox', padding: '12px 32px', color: 'white', borderRadius: '4px', letterSpacing: '0.0107 em'}}>
          Client data updated
        </div>
      </Snackbar>

    </div>
  )
};