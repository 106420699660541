import * as React from "react";
import {
  Edit,
  SimpleForm,
  required,
  NumberInput,
  TextInput,
  SimpleFormIterator,
  ImageInput,
  ImageField,
  FormDataConsumer,
  Labeled,
  ArrayInput,
  FileInput,
  FileField,
  BooleanInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import {CategoriesTreeInput} from "./CategoriesTreeInput";
import cfg from "../../Config";
import {toolbarOptions} from "../../common/ToolbarOptions";

export const ProductEdit = (props) => {
  return (<Edit {...props}>
    <SimpleForm>
      <BooleanInput fullWidth={true} source="is_show" />

      <TextInput fullWidth={true} source="name_in_url"/>

      <TextInput fullWidth={true} source="seo_title" validate={required()}/>
      <TextInput fullWidth={true} source="seo_description" validate={required()}/>
      <TextInput fullWidth={true} source="seo_keywords" validate={required()}/>

      <TextInput fullWidth={true} source="title" validate={required()}/>
      <TextInput fullWidth={true} source="sku" validate={required()}/>
      <CategoriesTreeInput source="category_id"  validate={required()}/>
      <NumberInput fullWidth={true} source="price" step={1} min={1} defaultValue={100}/>
      <RichTextInput source="description" validate={required()} toolbar={toolbarOptions}/>
      <RichTextInput source="details" validate={required()}/>
      <RichTextInput source="how_it_works"/>
      <NumberInput fullWidth={true} source="priority" step={1} min={0} defaultValue={1}/>

      <ArrayInput source="images">
        <SimpleFormIterator>
          <ImageInput source="image" label="Related pictures" accept="image/*">
            <ImageField source="url" title="title"/>
          </ImageInput>

          <FormDataConsumer>
            {({scopedFormData}) => {
              if (scopedFormData && typeof scopedFormData.url === "string") {
                return (
                  <div>
                    {scopedFormData.url ?
                      <Labeled label="Original image">
                        <img alt={"AVATAR"}
                             src={cfg.backUrl + "/image/product_img/" + scopedFormData.url}/>
                      </Labeled>
                      : null}
                  </div>
                );
              }
            }}
          </FormDataConsumer>

        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="downloads">
        <SimpleFormIterator>
          <FileInput source="image" label="Related file">
            <FileField source="url" title="title"/>
          </FileInput>

          <FormDataConsumer>
            {({scopedFormData}) => {
              if (scopedFormData && typeof scopedFormData.url === "string") {
                return (
                  <div>
                    {scopedFormData.url ?
                      <Labeled label="File">
                        <a href={cfg.backUrl + "/image/product_dwnl/" + scopedFormData.url} target="_blank" rel="noreferrer">{scopedFormData.url}</a>
                      </Labeled>
                      : null}
                  </div>
                );
              }
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="packages">
        <SimpleFormIterator>
          <TextInput source="packageProduct.title" label="Title" disabled={true}/>
          <TextInput source="sku" label="Sku"/>
          <NumberInput source="amount" label="Amount"/>
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  </Edit>)
}