import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    ImageInput,
    ImageField,
    FormDataConsumer,
    Labeled,
    NumberInput, SimpleFormIterator, ArrayInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import cfg from "../Config";
import {makeStyles} from "@material-ui/core/styles";
import {DateField} from 'react-admin';

const useStyles = makeStyles({
    media: {
        height: '500px',
        width: '400px',
    },
    image: {
        height: '500px',
        width: '400px',
        maxHeight: '500px',
    }
});

export const PostEdit = (props) => {
    const classes = useStyles(props);
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput fullWidth={true} disabled label="Id" source="id"/>

                <TextInput fullWidth={true} source="seo_title" validate={required()}/>
                <TextInput fullWidth={true} source="seo_description" validate={required()}/>
                <TextInput fullWidth={true} source="seo_keywords" validate={required()}/>

                <ImageInput source="image" label="select image" accept="image/*" validate={required()}>
                    <ImageField classes={classes} source="src" title="title"/>
                </ImageInput>

                <FormDataConsumer>
                    {({formData}) => {
                        if (typeof formData.image === "string") {
                            return (
                                <div>
                                    <Labeled label="Original image">
                                        <img alt={"AVATAR"} className={classes.media}
                                             src={cfg.backUrl + "/image/post/" + formData.image}/>
                                    </Labeled>
                                </div>
                            );
                        }
                    }}
                </FormDataConsumer>

                <TextInput fullWidth={true} source="name" validate={required()}/>
                <TextInput fullWidth={true} source="name_in_url"/>
                <RichTextInput source="article" validate={required()}/>
                <NumberInput fullWidth={true} source="priority" step={1} min={1}/>
                <DateField showTime={true} fullWidth={true} source="date"/>

                <ArrayInput source="additionalPostInfo">
                    <SimpleFormIterator>
                        <RichTextInput label="article" source="article"/>
                        <ImageInput source="image" label="Related pictures" accept="image/*">
                            <ImageField classes={classes} source="src" title="title"/>
                        </ImageInput>

                        <FormDataConsumer>
                            {({formData, scopedFormData}) => {
                                if (scopedFormData && typeof scopedFormData.image === "string") {
                                    console.log(formData);
                                    return (
                                        <div>
                                            {scopedFormData.image ?
                                                <Labeled label="Original image">
                                                    <img alt={"AVATAR"} className={classes.media}
                                                         src={cfg.backUrl + "/image/post/" + scopedFormData.image}/>
                                                </Labeled>
                                                : null}
                                        </div>
                                    );
                                }
                            }}
                        </FormDataConsumer>

                    </SimpleFormIterator>
                </ArrayInput>

            </SimpleForm>
        </Edit>
    )
};

