import * as React from "react";
import {
  Edit,
  SimpleForm,
  required,
  NumberInput, TextInput, SelectInput, SimpleFormIterator, ArrayInput,
} from 'react-admin';
import {ClientData} from "./ClientData";

export const OrderEdit = (props) => {
  return (<Edit {...props} aside={<ClientData/>}>
    <SimpleForm>
      <TextInput fullWidth={true} disabled label="Id" source="id"/>
      <NumberInput fullWidth={true} source="price" step={1} min={0}/>

      {/*<ReferenceInput source="status" reference="shop/orders/statuses">*/}
      {/*  <SelectInput optionText="title"/>*/}
      {/*</ReferenceInput>*/}

      <SelectInput source="status" optionText="title" choices={[
        { id: 404, title: 'Cancel' },
        { id: 1, title: 'New' },
        { id: 2, title: 'In process' },
        { id: 3, title: 'In the way' },
        { id: 200, title: 'Done' },
      ]}/>

      <ArrayInput source="items">
        <SimpleFormIterator disableAdd>
          <TextInput source="id" disabled fullWidth={true} validate={required()}/>
          <TextInput source="title" fullWidth={true} validate={required()}/>
          <TextInput source="amount" fullWidth={true} validate={required()}/>
          <TextInput source="price" fullWidth={true} validate={required()}/>
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput fullWidth={true} source="description" validate={required()} multiline={true}/>
    </SimpleForm>
  </Edit>)
}