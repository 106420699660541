import * as React from "react";
import {Datagrid, List, TextField} from 'react-admin';

export const CategoryList = props => (
  <List  {...props}>
    <Datagrid rowClick={"edit"}>
      <TextField source="id" label="ID"/>
      <TextField source="parentTitle" label="Parent"/>
      <TextField source="title" label="Title"/>
      <TextField source="priority" label="Priority"/>
    </Datagrid>
  </List>
);