import * as React from 'react';
import {useListContext} from 'react-admin';
import {
  Grid,
  Card,
  CardMedia,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import cfg from "../Config";


const useStyles = makeStyles({
  root: {
    marginTop: '1em',
  },
  media: {
    height: '150px',
  },
  title: {
    paddingBottom: '0.5em',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});

export const GalleryGrid = props => {
  const classes = useStyles(props);
  const {data, ids} = useListContext();

  const openEditView = (id) => {
    window.open(window.location.href + "/" + data[id].id, "_self");
  }

  return ids ? (
    <Grid container spacing={2} className={classes.root}>
      {ids.map(id => (
        <Grid key={id} xs={12} sm={6} md={3} lg={2} xl={2} item>
          <Card onClick={() => openEditView(id)}>
            <CardMedia
              image={cfg.backUrl + "/image/gallery/" + data[id].image}
              className={classes.media}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  ) : null;
};
