export const toolbarOptions = {
  container: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent

    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],

    ['link', 'image', 'video'],

    ['clean']                                         // remove formatting button
  ],
  handlers: {
    // video: function(value) {
    //   if (value) {
    //     const href = prompt('Enter the URL');
    //     const parts = href.split('/');
    //     const id = parts[parts.length-1];
    //     const link = `https://www.youtube.com/embed/${id}?showinfo=0`;
    //     const html = `<div class="ql-video"><iframe class="ql-video-iframe" src="${link}" width="560" height="315"/></div>`;
    //
    //     const delta = this.quill.clipboard.convert(html);
    //     this.quill.setContents(delta, 'silent');
    //   } else {
    //     this.quill.format('video', false);
    //   }
    // }
  }
};
