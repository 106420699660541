import {useEffect, useState} from "react";
import customDataProvider from "../../provider/CustomDataProvider";
import {Select} from "@material-ui/core";
import * as React from "react";
import {Labeled, useInput} from "react-admin";

export const CategoriesTreeInput = (props) => {
  const {
    input: {value, onChange},
    meta: {touched, error},
    isRequired,
  } = useInput(props);

  const {
    source,
    label
  } = props;

  const [categories, setCategories] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    customDataProvider.getCategoriesTree().then(response => {
      setCategories([
        {
          id: 0,
          parent_id: 0,
          title: '-',
          items: [{
            id: 0,
            parent_id: 0,
            title: '-',
          }]
        },
        ...response.data,
      ])
      setIsLoaded(true)
    })
  }, [])

  const change = (e) => {
    onChange(e.target.value)
  }

  if (!isLoaded) {
    return <p>Loading...</p>
  }

  return (
    <Labeled source={source} label={label} isRequired={isRequired}>
      <Select native defaultValue={value} onChange={change} error={!!(touched && error)}>
        {categories.map(category => (
          <optgroup label={category.title} key={category.id}>
            {category.items.map(item => (
              <option value={item.id} key={item.id}>{item.title}</option>
            ))}
          </optgroup>
        ))}
      </Select>
    </Labeled>
  )
}