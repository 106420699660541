import * as React from "react";
import {
  Create,
  SimpleForm,
  ImageInput,
  ImageField,
  required,
  NumberInput,
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  image: {
    height: '500px',
    width: '400px',
    maxHeight: '500px',
  }
});

export const GalleryCreate = (props) => {
  const classes = useStyles(props);
  return (<Create {...props}>
    <SimpleForm>
      <ImageInput source="image" label="Related pictures" accept="image/*" validate={required()}>
        <ImageField classes={classes} source="src" title="title"/>
      </ImageInput>
      <NumberInput fullWidth={true} source="priority" step={1} min={1}/>
    </SimpleForm>
  </Create>)
}